<footer>
  <button
    (click)="decreaseFontSize()"
    [disabled]="changingAppFont || currentFontSize === min"
    mat-button
    title="Click to decrease font size"
  >
    <mat-icon [inline]="true">remove</mat-icon>
  </button>
  <span
    class="footer-copyright"
    tabindex="-1"
    tabindex="0"
  >
    <mat-icon [inline]="true">copyright</mat-icon>
    <span
      aria-label="End of page"
      aria-details="end of page"
    >Blind Sailing Association of Canada</span>
  </span>
  <button
    (click)="increaseFontSize()"
    [disabled]="changingAppFont || currentFontSize === max"
    mat-button
    title="Click to increase font size."
  >
    <mat-icon [inline]="true">add</mat-icon>
  </button>
</footer>
