export enum UserAccessFields {
  CreateAccounts = 'createAccounts',
  CreateBoat = 'createBoat',
  CreateChallenge = 'createChallenge',
  CreateClinic = 'createClinic',
  CreateDocument = 'createDocument',
  CreateSail = 'createSail',
  CreateSailRequest = 'createSailRequest',
  CreateSocial = 'createSocial',
  DeletePictures = 'deletePictures',
  DownloadSailRequests = 'downloadSailRequests',
  DownloadSails = 'downloadSails',
  EditBoat = 'editBoat',
  EditChallenge = 'editChallenge',
  EditClinic = 'editClinic',
  EditDocument = 'editDocument',
  EditMaintenanceRequest = 'editMaintenanceRequest',
  EditSail = 'editSail',
  EditSailRequest = 'editSailRequest',
  EditSocial = 'editSocial',
  EditUserAccess = 'editUserAccess',
  EditUserProfile = 'editUserProfile',
  JudgeChallenge = 'judgeChallenge',
  LinkAccounts = 'linkAccounts',
  ResolveMaintenanceRequest = 'resolveMaintenanceRequest',
  ViewChallengeAccomplishments = 'viewChallengeAccomplishments',
  ViewUserSails = 'viewUserSails',
}
