<table
  role="list"
  [id]="id"
>
  <caption *ngIf="title || captionActions?.length">
    <h4
      *ngIf="title"
      tabindex="0"
    >{{title}}</h4>
    <div class="caption-controls">
      <button
        (click)="captionActionsCallBack(action.name)"
        *ngFor="let action of captionActions"
        [title]="action.tooltip"
        aria-hidden="true"
        class="caption-control"
        tabindex="0"
      >
        <mat-icon [inline]="true">{{action.icon}}</mat-icon>
      </button>
    </div>
  </caption>
  <ng-content></ng-content>
</table>
