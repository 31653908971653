<div class="app-container mat-app-background">
  <app-header
    [userProfile]="userProfile"
    (logoutListener)="logout()"
    [isDarkTheme]="className === 'darkMode'"
    (toggleDarkTheme)="toggleDarkTheme($event)"
  >
  </app-header>
  <mat-progress-bar
    *ngIf="isLoading"
    class="loader"
    color="silver"
    mode="indeterminate"
  ></mat-progress-bar>
  <main>
    <router-outlet></router-outlet>
  </main>
  <app-footer
    (changeFontSize)="changeFontSize($event)"
    [changingAppFont]="changingAppFont"
  ></app-footer>
</div>
