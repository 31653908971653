<div class="image-list">
  <div
    *ngFor="let picture of picturesArray; let i = index;"
    class="image-list-item"
    [ngSwitch]="picture.media_type"
  >
    <h5>{{picture.title || '&nbsp;'}}</h5>
    <img
      (click)="showMediaDialog.emit(picture)"
      *ngSwitchCase="MediaTypes.Picture"
      [height]="height"
      async
      attr.aria-label="picture {{i+1}} of {{picturesArray.length}}. comment: {{picture.description || 'none'}}"
      class="clickable"
      src="{{picture.url}}?width={{width}}"
      style="border: none;"
    >
    <div
      *ngSwitchCase="MediaTypes.Video"
      class="video-container"
    >
      <mat-icon>play_circle</mat-icon>
      <video
        (click)="showMediaDialog.emit(picture)"
        [height]="height"
        [width]="width"
        async
        attr.aria-label="video {{i+1}} of {{picturesArray.length}}. comment: {{picture.description || 'none'}}"
        class="clickable"
        preload="none"
        src="{{picture.url}}"
      ></video>
    </div>

    <img
      (click)="showMediaDialog.emit(picture)"
      *ngSwitchDefault
      [height]="height"
      [width]="width"
      async
      attr.aria-label="picture {{i+1}} of {{picturesArray.length}}. comment: {{picture.description || 'none'}}"
      class="clickable"
      src="{{picture.url}}?width={{width}}"
    >
    <div *ngIf="allowDelete && canDelete(picture)">
      <button
        (click)="deleteClick.emit(picture)"
        aria-label="Click to delete this picture"
        mat-raised-button
      >Delete</button>
    </div>
    <div *ngIf="canEdit(picture)">
      <a
        [routerLink]="editMediaRoute(picture.id)"
        aria-label="Click to edit this picture"
        mat-raised-button
      >Edit</a>
    </div>
    <div
      (click)="goToProfile.emit(picture.posted_by)"
      *ngIf="showAuthor"
      class="clickable"
    >
      <label>by: </label> {{picture.posted_by.name}}
    </div>
    <div
      *ngIf="showEntity"
      (click)="goToEntity.emit({id: picture.media_for_id, type: picture.media_for_type})"
      class="clickable"
    >
      <label class="clickable">View {{EntityLabels[picture.media_for_type]}}</label>
    </div>
    <pre
      *ngIf="picture.description"
      tabindex="0"
    >{{picture.description || '&nbsp;'}}</pre>
  </div>
</div>
